import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/about";
import Countdown from "./pages/countdown";
import Contact from "./pages/contact";
import LeftPanel from "./LeftPanel";
import twlogo from "./images/tailwindcss-logo.svg";
import reactlogo from "./images/react-logo.svg";
import "./i18n"; // Ensure you import the i18n configuration
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <header className="flex-shrink-0">
          <Navbar />
        </header>
        <div className="flex-grow flex flex-col lg:flex-row">
          <LeftPanel className="w-full lg:w-1/3" />
          <main className="w-full lg:w-2/3 pt-12">
            {/* Main content takes full width on small screens, 2/3 width on larger screens */}
            <Routes>
              <Route path="/" element={<Countdown />} />
              <Route path="/countdown" element={<Countdown />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </main>
        </div>

        <footer className="flex justify-center bg-gray-800 py-4 pl-8">
          <img
            src={twlogo}
            className="h-6 w-auto"
            alt={t("app.footer.tailwind_image_alt")}
          />
          <img
            src={reactlogo}
            className="h-6 w-auto pl-4"
            alt={t("app.footer.react_image_alt")}
          />
        </footer>
      </div>
    </Router>
  );
}

export default App;
