// Filename - pages/countdown.tsx

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../ProgressBarStyles.css";
import { useTranslation } from "react-i18next";
import DateComponent from "../components/DateComponent";

// List of Saskatchewan holidays for 2024 and 2025
interface Holiday {
  name: string;
  date: Date;
}

const holidays: Holiday[] = [
  { name: "New Year's Day 2024", date: new Date(2024, 0, 1) },
  { name: "Family Day 2024", date: new Date(2024, 1, 19) },
  { name: "Good Friday 2024", date: new Date(2024, 2, 29) },
  { name: "Easter Monday 2024", date: new Date(2024, 3, 1) },
  { name: "Victoria Day 2024", date: new Date(2024, 4, 20) },
  { name: "Canada Day 2024", date: new Date(2024, 6, 1) },
  { name: "Saskatchewan Day 2024", date: new Date(2024, 7, 5) },
  { name: "Labour Day 2024", date: new Date(2024, 8, 2) },
  { name: "Thanksgiving Day 2024", date: new Date(2024, 9, 14) },
  { name: "Remembrance Day 2024", date: new Date(2024, 10, 11) },
  { name: "Christmas Day 2024", date: new Date(2024, 11, 25) },
  { name: "Boxing Day 2024", date: new Date(2024, 11, 26) },
  { name: "New Year's Day 2025", date: new Date(2025, 0, 1) },
  { name: "Family Day 2025", date: new Date(2025, 1, 17) },
  { name: "Good Friday 2025", date: new Date(2025, 3, 18) },
  { name: "Easter Monday 2025", date: new Date(2025, 3, 21) },
  { name: "Victoria Day 2025", date: new Date(2025, 4, 19) },
  { name: "Canada Day 2025", date: new Date(2025, 6, 1) },
  { name: "Saskatchewan Day 2025", date: new Date(2025, 7, 4) },
  { name: "Labour Day 2025", date: new Date(2025, 8, 1) },
  { name: "Thanksgiving Day 2025", date: new Date(2025, 9, 13) },
  { name: "Remembrance Day 2025", date: new Date(2025, 10, 11) },
  { name: "Christmas Day 2025", date: new Date(2025, 11, 25) },
  { name: "Boxing Day 2025", date: new Date(2025, 11, 26) },
];

const getNextHolidayIndex = (): number => {
  const now = Date.now();
  const index = holidays.findIndex((holiday) => holiday.date.getTime() > now);
  return index !== -1 ? index : holidays.length - 1; // Return last index if not found
};

// Countdown component that accepts a target date
interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownProgressBars: React.FC<{ targetDate: Date }> = ({
  targetDate,
}) => {
  // Move useTranslation inside the component
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(null);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const diff = targetDate.getTime() - now.getTime();

      if (diff <= 0) {
        setTimeLeft(null);
        return;
      }

      const seconds = Math.floor((diff / 1000) % 60);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));

      setTimeLeft({ days, hours, minutes, seconds });
    };

    calculateTimeLeft(); // Initial call
    const interval = setInterval(calculateTimeLeft, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [targetDate]);

  if (!timeLeft) {
    return <p>{t("countdown.arrived_message")}</p>;
  }

  const { days, hours, minutes, seconds } = timeLeft;
  // Determine the max value for the days progress bar based on your conditions
  const now = new Date();
  let daysMax: number;

  if (days <= 7) {
    daysMax = 7;
  } else {
    // Get the number of days in the current month
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const daysInCurrentMonth = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();

    if (days <= daysInCurrentMonth) {
      daysMax = daysInCurrentMonth;
    } else {
      // Get the number of days in the current year
      const isLeapYear =
        (currentYear % 4 === 0 && currentYear % 100 !== 0) ||
        currentYear % 400 === 0;
      daysMax = isLeapYear ? 366 : 365;
    }
  }

  return (
    <div>
      <div>
        <h4>
          {t("countdown.progress_bars.days")}: {days} / {daysMax}
        </h4>
        <progress value={days} max={daysMax} />
      </div>
      <div>
        <h4>
          {t("countdown.progress_bars.hours")}: {hours} / 24
        </h4>
        <progress value={hours} max="24" />
      </div>
      <div>
        <h4>
          {t("countdown.progress_bars.minutes")}: {minutes} / 60
        </h4>
        <progress value={minutes} max="60" />
      </div>
      <div>
        <h4>
          {t("countdown.progress_bars.seconds")}: {seconds} / 60
        </h4>
        <progress value={seconds} max="60" />
      </div>
    </div>
  );
};

// Main Countdown component
const Countdown: React.FC = () => {
  const { t } = useTranslation();
  const [selectedHolidayIndex, setSelectedHolidayIndex] = useState<number>(
    getNextHolidayIndex()
  );

  const selectedHoliday = holidays[selectedHolidayIndex];

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const index = parseInt(event.target.value, 10);
    setSelectedHolidayIndex(index);
  };

  return (
    <div className="pl-8">
      {/* Add Helmet to dynamically set the page title */}
      <Helmet>
        <title>
          {t("countdown.title")} {selectedHoliday.name}
        </title>
      </Helmet>

      <div className="border-black border w-[19rem] p-4 mb-4 text-center">
        <b>
          <div className="text-xl">{t("countdown.select_heading")}</div>
        </b>
        <div className="pt-4">
          <select onChange={handleSelectChange} value={selectedHolidayIndex}>
            {holidays.map((holiday, index) => (
              <option key={index} value={index}>
                {holiday.name}
              </option>
            ))}
          </select>
        </div>
        {/* Use DateComponent to display the current holiday date */}
        <div className="pt-4">
          <p>
            <strong>{selectedHoliday.name.replace(/\d{4}$/, "").trim()}</strong>
            <br />
            <DateComponent date={selectedHoliday.date} />
          </p>
        </div>
      </div>
      <CountdownProgressBars targetDate={selectedHoliday.date} />
    </div>
  );
};

export default Countdown;
