import React from "react";
import degreeImg from "../images/degree.png";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>{t("about.title")}</Helmet>
      <img
        src={degreeImg}
        alt={t("about.degree_alt")}
        className="md:w-full lg:w-2/3 h-auto block"
      />
    </div>
  );
};

export default About;
