import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // State to control menu visibility

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle menu open/close
  };

  const { t } = useTranslation();

  return (
    <nav className="relative flex items-center justify-between p-4 bg-gray-800 text-white">
      <button
        className="block md:hidden focus:outline-none"
        onClick={toggleMenu}
        aria-label={t("navlinks.menu_label")}
        aria-expanded={isOpen}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          {isOpen ? (
            // Close Icon
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          ) : (
            // Hamburger Icon
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          )}
        </svg>
      </button>
      <LanguageSwitcher />
      <ul className="hidden md:flex space-x-4">
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              clsx("px-2 py-1", isActive ? "text-yellow-500" : "text-white")
            }
          >
            {t("navlinks.menu_item.mi_countdown")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              clsx("px-2 py-1", isActive ? "text-yellow-500" : "text-white")
            }
          >
            {t("navlinks.menu_item.mi_about")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              clsx("px-2 py-1", isActive ? "text-yellow-500" : "text-white")
            }
          >
            {t("navlinks.menu_item.mi_contact")}
          </NavLink>
        </li>
      </ul>
      <div
        className={clsx(
          "absolute top-full left-0 w-full bg-gray-800 text-white md:hidden",
          {
            block: isOpen,
            hidden: !isOpen,
          }
        )}
      >
        <ul className="flex flex-col space-y-4 p-4">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                clsx("px-2 py-1", isActive ? "text-yellow-500" : "text-white")
              }
              onClick={() => setIsOpen(false)} // Close menu on link click
            >
              {t("navlinks.menu_item.mi_countdown")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                clsx("px-2 py-1", isActive ? "text-yellow-500" : "text-white")
              }
              onClick={() => setIsOpen(false)} // Close menu on link click
            >
              {t("navlinks.menu_item.mi_about")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                clsx("px-2 py-1", isActive ? "text-yellow-500" : "text-white")
              }
              onClick={() => setIsOpen(false)} // Close menu on link click
            >
              {t("navlinks.menu_item.mi_contact")}
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
