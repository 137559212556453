// Filename: components/DateComponent.tsx

import React from "react";
import { useTranslation } from "react-i18next";

interface DateComponentProps {
  date: Date;
}

const DateComponent: React.FC<DateComponentProps> = ({ date }) => {
  const { i18n } = useTranslation();
  const formattedDate = new Intl.DateTimeFormat(i18n.language, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);

  return <span>{formattedDate}</span>;
};

export default DateComponent;
