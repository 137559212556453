import React from "react";
import PelicanImage from "./images/PelicanCoder.png";
function LeftPanel({ className = "" }) {
  return (
    <div className={`p-4 ${className}`}>
      <img src={PelicanImage} alt="Pelican at keyboard" height="1200px" />
    </div>
  );
}

export default LeftPanel;
