// Filename - pages/contact.js

import React from "react";
import { Helmet } from "react-helmet";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row mb-4 sm:mb-4 md:mb-4">
      {/* Add Helmet to set the page title */}
      <Helmet>
        <title>{t("contact.title")}</title>
      </Helmet>
      <EnvelopeIcon className="mr-1 w-6 h-6" />
      <a href="mailto:contact@pelicancoder.info" className="underline">
        contact@pelicancoder.info
      </a>
    </div>
  );
};

export default Contact;
